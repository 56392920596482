import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.sass';

export default ({ onPress }) => (
  <div className={clsx(
      styles.container,
      onPress ? '' : styles.containerHidden
    )}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.4 36.4"
      width='100%'
      height='100%'
      onClick={onPress}>
      <path className={styles.arrow}
        d="M46.9 15.3H11.8l9.9-9.9c1.1-1.2 1.1-2.9 0-4.1-1.1-1.2-2.9-1.2-4.1-.1l-.1.1L.6 18.2l16.9 17c.6.5 1.3.8 2.1.8s1.5-.3 2.1-.8c1.1-1.2 1.1-3 0-4.2l-9.9-9.8h35.1c1.6 0 2.9-1.3 2.9-3 0-1.6-1.3-2.9-2.9-2.9z" fill="#464646"/>
    </svg>
  </div>
);