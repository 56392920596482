import React from 'react';
import i18n from '../../../locale';
import styles from './styles.module.sass';

export default [{
  value: 'Vehicle Care',
  text: i18n('Vehicle Care'),
  subtext: i18n('Remote vehicle management'),
  Icon: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
      <defs>
        <clipPath id="clip-path-vc"><rect className={styles.vcOne} width="92" height="92" rx="8" ry="8"/></clipPath>
        <linearGradient id="linear-gradient-vc" x1="3386.3" y1="-875.34" x2="3387.19" y2="-874.19" gradientTransform="matrix(105.71, 0, 0, -105.71, -357956.53, -92434.8)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#1136a1"/><stop offset="1" stop-color="#315bdb"/></linearGradient>
      </defs>
      <title>OB+</title>
      <g data-name="Layer 2">
        <g data-name="Group 13490">
          <g className={styles.vcTwo}>
            <g data-name="Mask Group 1243">
              <g data-name="Group 13217">
                <path data-name="Path 6488" className={styles.vcThree} d="M4.57-7.71H89.14A10.48,10.48,0,0,1,99.71,2.69h0V87.6A10.48,10.48,0,0,1,89.14,98H4.57A10.49,10.49,0,0,1-6,87.6h0V2.69A10.48,10.48,0,0,1,4.57-7.71Z"/>
              </g>
            </g>
          </g>
          <g data-name="Group 13232">
            <path data-name="Intersection 1" className={styles.vcFour} d="M61.07,70.22h0a.5.5,0,0,1-.22-.13h0a38.51,38.51,0,0,1-19.08-29l-1.3-12.34,20.7-7.4,20.53,7.4L80.4,41.07a33.88,33.88,0,0,1-3.75,12.48l-5.06-4.42a12.16,12.16,0,1,0-3.15,3.76l5.64,4.94a48.28,48.28,0,0,1-5.71,6.8,35.37,35.37,0,0,1-7.07,5.46h0c-.1.1-.17.15-.22.15ZM15.77,66.91a1.45,1.45,0,0,1-1.44-1.45V49a4.51,4.51,0,0,1,.78-2.57l1.78-2.65-5.51-1.35a1.35,1.35,0,0,1-1-.84,1.4,1.4,0,0,1,1.3-1.92h4.1a1.57,1.57,0,0,1,1.18.52l1.3,1.5,6.27-9.35A3.42,3.42,0,0,1,27,30.83c1.87-.22,6-.55,10.06-.72l1.21,10.55c.1.86.23,1.74.38,2.6H26.33l.6,1.55H39a45.79,45.79,0,0,0,2.81,8.84l-7.4,0a2.83,2.83,0,0,0-2.54,1.6l-1.25,2.64a1.65,1.65,0,0,0,1.48,2.36H35.7a1.4,1.4,0,0,0,.42.05l6.55,0,2.65,0c.63,1,1.28,1.89,1.92,2.74H25.5v2.43A1.46,1.46,0,0,1,24,66.91ZM19.2,49.43a.8.8,0,0,0-.74.85v1.76a.83.83,0,0,0,.74.87l7.66,0a.69.69,0,0,0,.58-.33,1,1,0,0,0,.08-.91L27,50.27a1.26,1.26,0,0,0-1.14-.84ZM55,50.53a9.35,9.35,0,0,1-3.11-5.61h5.34a1.31,1.31,0,0,0,1.19-.78l1.21-2.74L62,47.57a1.44,1.44,0,0,0,.49.63,1.33,1.33,0,0,0,.73.22l.25,0a1.26,1.26,0,0,0,.82-.56l2-2.92h4A9.29,9.29,0,0,1,55,50.53Zm8.31-4.58h0L60.86,39.5a1.3,1.3,0,0,0-1.21-.83,1.35,1.35,0,0,0-.46.09,1.26,1.26,0,0,0-.72.68l-1.61,3.65H51.78a9.29,9.29,0,0,1,18.57,0H65.94a1.32,1.32,0,0,0-1.07.55l-1.56,2.3Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
},{
  value: 'Fleet',
  text: i18n('Fleet'),
  subtext: i18n('Web-based fleet management'),
  Icon: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 92 92">
      <defs>
        <linearGradient id="linear-gradient-fleet" x1="3382.2" y1="-752.67" x2="3380.84" y2="-754.91" gradientTransform="matrix(92, 0, 0, -92, -311068.62, -69278)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#3ffbc8"/>
          <stop offset="1" stop-color="#207e64"/>
        </linearGradient>
      </defs>
      <g data-name="Layer 2">
        <rect data-name="Rectangle 8624" className={styles.fleetOne} width="92" height="92" rx="8" ry="8"/>
        <path className={styles.fleetTwo} d="M46.65,56.66,33.89,35.2a1.54,1.54,0,0,1,1.33-2.32H92V24.42H21.16a5.88,5.88,0,0,0-5.88,5.88h0a5.86,5.86,0,0,0,.42,2.19L39,92H67.21L48.45,59.82H92V56.66Z"/>
        <path className={styles.fleetTwo} d="M46.65,56.66,33.89,35.2a1.54,1.54,0,0,1,1.33-2.32H92V24.42H21.16a5.88,5.88,0,0,0-5.88,5.88h0a5.86,5.86,0,0,0,.42,2.19L39,92H67.21L48.45,59.82H92V56.66Z"/>
      </g>
    </svg>
  )
}, {
  value: 'Driver App',
  text: i18n('Drive'),
  subtext: i18n('Service for every driver'),
  Icon: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 92 92">
      <defs>
        <linearGradient id="linear-gradient-drive" x1="3382.63" y1="-630.95" x2="3381.01" y2="-632.26" gradientTransform="matrix(92, 0, 0, -92, -311056.83, -58081.33)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#f63e87"/>
          <stop offset="1" stop-color="#f59c62"/>
        </linearGradient>
      </defs>
      <g data-name="Layer 2">
        <rect data-name="Rectangle 8841" className={styles.driveOne} width="92" height="92" rx="8" ry="8"/>
        <g data-name="Group 12848">
          <path data-name="Path 6173" className={styles.driveTwo} d="M79.57,45.38c-2.11-1.57-4.8-3.37-6.52-4.49l.9-1.66a.67.67,0,0,1,.59-.35H78a2.92,2.92,0,0,0,2.16-1l1.23-1.36a1.49,1.49,0,0,0-1.11-2.5H76.71a5.58,5.58,0,0,0-4.86,3l-1,1.83-3.09-5.63c-2.54-4.53-2.74-7.14-20.57-7.14H45.71c-17.84,0-18,2.6-20.58,7.14l-3.08,5.63-1-1.83a5.58,5.58,0,0,0-4.86-3H12.65a1.49,1.49,0,0,0-1.11,2.5l1.23,1.36a2.92,2.92,0,0,0,2.16,1h3.42a.67.67,0,0,1,.59.35l.9,1.66c-1.72,1.13-4.42,2.93-6.52,4.5a5.54,5.54,0,0,0-2.13,5.05c.33,3,.92,7.71,1.83,11.86a3.52,3.52,0,0,0,1.58,2.26l2.18,1.34s.19.79.79,2.76a1.52,1.52,0,0,0,1.24,1.09,36.13,36.13,0,0,0,8.67.09,2.5,2.5,0,0,0,1.92-1.32,7.07,7.07,0,0,0,.85-2.26l1.38-.06a1.3,1.3,0,0,0,1-.59l2.13-6.36a3.9,3.9,0,0,1,3.51-2.45l11,0,5.34,0a3.86,3.86,0,0,1,3.47,2.34l2.17,6.47a1.3,1.3,0,0,0,1,.59l1.38.06a7.1,7.1,0,0,0,.85,2.26,2.5,2.5,0,0,0,1.92,1.32,36.31,36.31,0,0,0,8.67-.09,1.53,1.53,0,0,0,1.24-1.09c.6-2,.79-2.76.79-2.76l2.18-1.34a3.52,3.52,0,0,0,1.57-2.26c.9-4.15,1.5-8.86,1.83-11.86A5.55,5.55,0,0,0,79.57,45.38ZM32.46,52a1.12,1.12,0,0,1-1.12,1.13h-11A2.29,2.29,0,0,1,18,50.82h0v-1.2a1.17,1.17,0,0,1,1.17-1.17H30.12a2.33,2.33,0,0,1,2.33,2.33h0ZM26.29,40.57a1.42,1.42,0,0,1-1.42-1.42h0a1.43,1.43,0,0,1,.17-.68l2.09-3.82c2.3-4.1,2.48-6.47,18.64-6.47h1.34c16.15,0,16.34,2.36,18.63,6.47l2.09,3.82a1.42,1.42,0,0,1-.56,1.93h0a1.39,1.39,0,0,1-.68.18ZM74.87,50.82a2.29,2.29,0,0,1-2.29,2.29h-11A1.12,1.12,0,0,1,60.44,52v-1.2a2.33,2.33,0,0,1,2.33-2.33H73.71a1.17,1.17,0,0,1,1.17,1.17h0Z"/>
          <path data-name="Path 6174" className={styles.driveTwo} d="M54.48,58.55l-10.77,0-5.24,0a1.94,1.94,0,0,0-1.72,1.2L35.1,64.65H57.84l-1.68-5A1.91,1.91,0,0,0,54.48,58.55Z"/>
        </g>
      </g>
    </svg>
  )
}]